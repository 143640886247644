<template>
    <div>
      <div v-if="isRegistered">
        <base-card>
          <h3>
            Your product has been successfully registered, Please wait for a
            confirmation from admin.
          </h3>
          <button @click="registerAnotherProduct" class="btn btn-success">
            Add another product
          </button>
        </base-card>
      </div>
      <div v-if="isLoading">
        <base-card>
          <base-spinner></base-spinner>
          <p>PLease wait a moment for your product to be registered.</p>
        </base-card>
      </div>
      <base-card v-if="!isRegistered && !isLoading" class="container">
        <form @submit.prevent="addProduct">
          <div class="form-group">
            <label for="productName">Product Name</label>
            <input
              type="text"
              class="form-control"
              id="productName"
              v-model.trim="productName"
            />
          </div>
          <div class="form-group">
            <label for="image-url">Image Url</label>
            <input
              type="file"
              class="form-control"
              id="image-url"
              accept="image/*"
              ref="fileInput"
              @change="onFilePicked"
              multiple
            />
          </div>
          <div class="viewImages">
            <img :src="imageUrl" alt="" class="d-block w-auto h-0.1" />
          </div>

          <div class="form-group">
            <label for="price">Price</label>
            <input
              type="number"
              class="form-control price"
              id="price"
              v-model="price"
              placeholder="200,000"
            /><span> UGX</span>
          </div>
  
          <div class="form-group">
            <label for="description"
              >Add a description -
              <span class="text-muted">(optional)</span></label
            >
            <textarea
              name="description"
              id="description"
              rows="5"
              class="form-control"
              v-model="description"
            ></textarea>
          </div>
          <p v-if="!formIsValid" style="color: red">
            Please make sure you provided all the required information!!
          </p>
          <button class="btn btn-primary">Submit</button>
        </form>
      </base-card>
    </div>
  </template>
  
  <script>
  import Compressor from "compressorjs";
  export default {
    data() {
      return {
        isLoading: false,
        formIsValid: true,
        isRegistered: false,
        imageUrl: [],
        image: [],
        price: null,
        description: "",
        productName: "",
        approved: false,
        sold: false,
      };
    },
    methods: {
      validateForm() {
        this.formIsValid = true;
        if (this.price === null) {
          this.formIsValid = false;
        }
        if (this.imageUrl.length <= 0) {
          this.formIsValid = false;
        }
        if (this.productName === "") {
          this.formIsValid = false;
        }
      },
  
      onFilePicked(event) {
        const files = event.target.files;
        let filename = files[0].name;
        if (filename.lastIndexOf(".") <= 0) {
          return alert("Please add a value");
        }
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageUrl = fileReader.result;
        });
        fileReader.readAsDataURL(files[0]);
        // console.log("The whole files", files)
        // console.log("This is the image url", this.imageUrl);
        let compressedImages = [];
        for (let file of files) {
          // console.log(file, "original")
          new Compressor(file, {
            quality: 0.4,
            success(result) {
              const myFile = new File([result], result.name, {
                type: result.type,
              });
              // console.log("compressed:", myFile)
              compressedImages.push(myFile);
            },
          });
        }
        this.image = compressedImages;
        // console.log("Hello", this.image);
      },
      async addProduct() {
        this.validateForm();
  
        if (!this.formIsValid) {
          return;
        }
  
        const product = {
          productName: this.productName,
          description: this.description,
          price: this.price,
          image: this.image,
          approved: false,
          sold: false,
        };
        this.isLoading = true;
  
        await this.$store.dispatch("marketplace/addProduct", product);
  
          (this.productName = ""),
          (this.image = []),
          (this.description = ""),
          (this.price = null),
          (this.isLoading = false),
        this.isRegistered = true;
      },
      registerAnotherProduct() {
        this.isRegistered = false;
        this.isLoading = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .viewImages {
    height: 100px;
  }
  .viewImages img {
    float: left;
    width: 100px;
    height: 100px;
    /* object-fit: cover; */
  }
  label {
    display: block;
  }
  #price {
    width: 80%;
    display: inline;
  }
  #price > span {
    padding: 5px;
  }
  </style>